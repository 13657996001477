@import "../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Merriweather:700,700italic,400,400italic|Titillium+Web:400,600|Playfair+Display:400,700&display=swap');


.link-button {
    cursor: pointer;
}

label,
:focus,
:active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
    color: #2b1508;
    font-weight: bold;
}